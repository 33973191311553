import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaCopy } from "react-icons/fa";
import apiService from "../../services/apiService";
import Loader from "../loader/Loader";

const CreateStore = ({ setActiveTab, onClose }) => {
  const [storeName, setStoreName] = useState("");
  const [storeCfID, setStoreCfID] = useState("");
  const [currentAcmID, setCurrentAcmID] = useState("");
  const [fkStoreStatus, setFkStoreStatus] = useState(0);
  const [aliasList, setAliasList] = useState([]);
  const [aliasCount, setAliasCount] = useState(0);
  const [storeStatuses, setStoreStatuses] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchStoreStatuses = async () => {
      try {
        const response = await apiService.get("/api/store-status");
        setStoreStatuses(response.data);
      } catch (error) {
        toast.error("Failed to fetch store statuses", { autoClose: 3000});
      }
    };
    fetchStoreStatuses();
  }, []);

  const handleFetchStores = async () => {
    setLoading(true);
    const response = await apiService
      .get(`/api/stores/FetchStoreInfo/${storeName}`)
      .then((response) => {
        if (response.data === null) {
          toast.error(
            "Failed to fetch store info! Please contact your Aws Administrator",
            { autoClose: 3000 }
          );
          return;
        }
        toast.info("Store infromation fetched successfully", {
          autoClose: 3000,
        });
        setStoreCfID(response.data.Id);
        setCurrentAcmID(response.data.ViewerCertificate.ACMCertificateArn);
        setFkStoreStatus(response.data.fkStoreStatus);
        setAliasList(response.data.Aliases.Items);
        setAliasCount(response.data.Aliases.Quantity);
        if (aliasCount > 1) {
          setFkStoreStatus(2);
        } else if (aliasCount > 10) {
          setFkStoreStatus(3);
        } else {
          setFkStoreStatus(1);
        }
        // console.log(response.data.Aliases.Items);
      })
      .catch((error) => {
        toast.error("Failed to fetch store info", { autoClose: 3000 });
      })
      .finally(() => {
        setLoading(false);
      });
    // setStoreStatuses(response.data);
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    setLoading(true);
    try {
      let aliasListStr = aliasList.join(",");
       await apiService.post("/api/stores", {
        storeName,
        currentAcmID,
        fkStoreStatus,
        aliasList: aliasListStr,
        // aliasList.tostring(),
        aliasCount,
        storeCfID,
      });
      toast.success("Store created successfully", { autoClose: 3000 });
      onClose();
      //window.location.reload();
      setActiveTab("store");
    } catch (error) {
      toast.error("Failed to create store", { autoClose: 3000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <ToastContainer />
      {loading && <Loader />}
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded shadow-md w-1/2"
      >
        <h2 className="text-2xl font-bold mb-4 text-center text-[#225282]">Create Store</h2>
        <div className="mb-4">
          <label
            htmlFor="storeName"
            className="block text-sm font-medium text-gray-700"
          >
            Store Name
          </label>
          <div className="flex flex-row gap-2">
            <input
              type="text"
              id="storeName"
              value={storeName}
              onChange={(e) => setStoreName(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
              required
            />
            <input
              className="bg-orange-500 mt-1 text-white py-2 px-4 rounded hover:bg-orange-600 my-1"
              type="button"
              value={"Fetch Store"}
              onClick={handleFetchStores}
            />
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="currentAcmID"
            className="block text-sm font-medium text-gray-700 "
          >
            Current ACM ID
          </label>
          <div className="flex flex-row gap-2">
            <input
              type="textarea"
              id="currentAcmID"
              value={currentAcmID}
              onChange={(e) => setCurrentAcmID(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
              required
              disabled
            />
            <FaCopy
              className="my-2 hover:cursor-pointer text-orange-500 hover:text-orange-600"
              size={26}
              onClick={() => navigator.clipboard.writeText(currentAcmID)}
            />
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="aliasList"
            className="block text-sm font-medium text-gray-700"
          >
            Available Stores
          </label>
          <ul className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded">
            {aliasList.map((alias, index) => (
              <li className="text-sm my-1" key={index}>
                {alias}
              </li>
            ))}
          </ul>
          <span className="text-xs">Count: {aliasCount}</span>
        </div>

        {/* <div className="mb-4">
            <label
              htmlFor="aliasCount"
              className="block text-sm font-medium text-gray-700"
            >
              Alias Count
            </label>
            <input
              type="text" // Changed to text to handle string input
              id="aliasCount"
              value={aliasCount}
              // onChange={(e) => setAliasCount(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
              required
              disabled
            />
          </div> */}

        <div className="mb-4">
          <label
            htmlFor="fkStoreStatus"
            className="block text-sm font-medium text-gray-700"
          >
            Store Status
          </label>
          <select
            id="fkStoreStatus"
            value={fkStoreStatus}
            onChange={(e) => setFkStoreStatus(parseInt(e.target.value))}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
            required
          >
            <option value="">Select Status</option>
            {storeStatuses.map((status) => (
              <option key={status.id} value={status.id}>
                {status.statusName}
              </option>
            ))}
          </select>
        </div>
        <button
          type="submit"
          className="w-full bg-orange-300 text-black py-2 px-4 rounded hover:bg-orange-500"
        >
          Create
        </button>
        <button
          type="button"
          onClick={onClose}
          className="w-full py-2 px-4 bg-gray-300 text-black rounded mt-2 hover:bg-gray-500"
        >
          Close
        </button>
      </form>
    </div>
  );
};

export default CreateStore;
