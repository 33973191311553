// components/Pagination.js
import React, { useState } from "react";

const Pagination = ({ currentPage, totalPages, totalRecords, onPageChange, onLimitChange, limit }) => {
  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    onPageChange(page);
  };

  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    onLimitChange(newLimit); 
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const startRecord = (currentPage - 1) * limit + 1;
  const endRecord = Math.min(currentPage * limit, totalRecords);

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between mb-2">
        <div className="text-sm text-gray-700">
          {`Showing ${startRecord} to ${endRecord} of ${totalRecords} records`}
        </div>
        <div className="flex items-center space-x-2">
          {/* Limit Dropdown */}
          <select
            value={limit}
            onChange={handleLimitChange}
            className="px-3 py-1 border rounded text-gray-800"
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          {/* Pagination Buttons */}
          <div className="flex space-x-2">
            {currentPage > 1 && (
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className="px-3 py-1 bg-gray-800 text-white rounded hover:bg-gray-700"
              >
                Previous
              </button>
            )}
            {pageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => handlePageChange(number)}
                className={`px-3 py-1 border rounded ${number === currentPage ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'}`}
              >
                {number}
              </button>
            ))}
            {currentPage < totalPages && (
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                className="px-3 py-1 bg-gray-800 text-white rounded hover:bg-gray-700"
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
