import React from "react";
import { XCircleIcon } from "@heroicons/react/solid";

const SuccessPopup = ({ onClose }) => {
  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white p-8 rounded shadow-lg flex flex-col items-center text-center">
        <XCircleIcon
          onClick={onClose}
          className="h-6 w-6 text-red-500 hover:text-red-700 absolute top-2 right-2 cursor-pointer"
        />
        <XCircleIcon className="h-24 w-24 mb-4 text-blue-500" />
        <h2 className="text-xl font-bold mb-4">Sit Back... Relax!</h2>
        <p>Your request was queued and will be processed shortly. You will be notified by email once it's done.</p>
        <button
          onClick={onClose}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default SuccessPopup;
