import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import apiService from "./services/apiService";
import { ToastContainer, toast } from "react-toastify";
import Loader from "./components/loader/Loader";

function Modal({ onClose, agency, onUpdateAgency }) {
  const [id, setId] = useState("");
  const [agencyName, setAgencyName] = useState("");
  const [agencyDomainName, setAgencyDomainName] = useState("");
  const [fkAssignedStore, setFkAssignedStore] = useState(0);
  const [fkAgencyStatus, setFkAgencyStatus] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [isDelete, setIsDelete] = useState(false);
  const [createdBy, setCreatedBy] = useState(0);
  const [updatedBy, setUpdatedBy] = useState(0);
  const [agencyStatuses, setAgencyStatuses] = useState([]);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (agency) {
      setId(agency.ID || "");
      setAgencyName(agency.agencyName || "");
      setAgencyDomainName(agency.agencyDomainName || "");
      setFkAssignedStore(agency.fkAssignedStore || 0);
      setFkAgencyStatus(agency.fkAgencyStatus || 0);
      setIsActive(agency.isActive || true);
      setIsDelete(agency.isDelete || false);
      setCreatedBy(agency.createdBy || 0);
      setUpdatedBy(agency.updatedBy || 0);
    }
  }, [agency]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setCreatedBy(decodedToken.userId);
      setUpdatedBy(decodedToken.userId);
    }

    const fetchAgencyStatuses = async () => {
      try {
        if (!token) {
          console.error("No authentication token found");
          return;
        }

        const response = await apiService.get("/api/agency-status");

        setAgencyStatuses(response.data);
      } catch (error) {
        console.error("Failed to fetch agency statuses", error);
      }
    };

    const fetchStores = async () => {
      try {
        if (!token) {
          console.error("No authentication token found");
          return;
        }

        const response = await apiService.get("/api/stores");

        setStores(response.data);
      } catch (error) {
        console.error("Failed to fetch stores", error);
      }
    };

    fetchAgencyStatuses();
    fetchStores();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await apiService.put(`/api/agencies/${id}`, {
        id,
        agencyName,
        agencyDomainName,
        fkAssignedStore,
        fkAgencyStatus,
        isActive,
        isDelete,
        createdBy,
        updatedBy,
      });
      toast.success("Agency updated successfully!", { autoClose: 2000 });
      onClose();
      onUpdateAgency({
        id,
        agencyName,
        agencyDomainName,
        fkAssignedStore,
        fkAgencyStatus,
        isActive,
        isDelete,
        createdBy,
        updatedBy,
      });
    } catch (error) {
      console.error("Failed to update agency", error);
      toast.error("Failed to update user.");
    } finally {
      setLoading(false);
    }
  };

  if (!agency) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <ToastContainer/>
      {loading && <Loader/>}
      <div        
        onClick={onClose}
        className="fixed inset-0 bg-black opacity-50 z-40"
      ></div>
      <form
        onSubmit={handleUpdate}
        className="relative z-50 bg-white p-6 rounded shadow-md max-w-md w-full"
      >
        <h2 className="text-2xl font-bold mb-4 text-center text-[#225282]">Update Agency</h2>
        <div className="mb-4">
          <label
            htmlFor="id"
            className="block text-sm font-medium text-gray-700"
          >
            ID
          </label>
          <input
            type="text"
            id="id"
            value={id}
            readOnly
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-100 text-gray-500 sm:text-sm"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="agencyName"
            className="block text-sm font-medium text-gray-700"
          >
            Agency Name
          </label>
          <input
            type="text"
            id="agencyName"
            value={agencyName}
            onChange={(e) => setAgencyName(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="agencyDomainName"
            className="block text-sm font-medium text-gray-700"
          >
            Agency Domain Name
          </label>
          <input
            type="text"
            id="agencyDomainName"
            value={agencyDomainName}
            onChange={(e) => setAgencyDomainName(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="fkAssignedStore"
            className="block text-sm font-medium text-gray-700"
          >
            Assigned Store
          </label>
          <select
            id="fkAssignedStore"
            value={fkAssignedStore}
            onChange={(e) => setFkAssignedStore(parseInt(e.target.value))}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
            required
          >
            <option value="">Select Store</option>
            {stores.map((store) => (
              <option key={store.ID} value={store.ID}>
                {store.storeName}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="fkAgencyStatus"
            className="block text-sm font-medium text-gray-700"
          >
            Agency Status
          </label>
          <select
            id="fkAgencyStatus"
            value={fkAgencyStatus}
            onChange={(e) => setFkAgencyStatus(parseInt(e.target.value))}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded"
            required
          >
            <option value="">Select Status</option>
            {agencyStatuses.map((status) => (
              <option key={status.id} value={status.id}>
                {status.statusName}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4 flex items-center">
          <input
            type="checkbox"
            id="isActive"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
            className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded"
          />
          <label
            htmlFor="isActive"
            className="ml-2 text-sm font-medium text-gray-700"
          >
            Active
          </label>
        </div>
        <div className="mb-4 flex items-center">
          <input
            type="checkbox"
            id="isDelete"
            checked={isDelete}
            onChange={(e) => setIsDelete(e.target.checked)}
            className="h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded"
          />
          <label
            htmlFor="isDelete"
            className="ml-2 text-sm font-medium text-gray-700"
          >
            Delete
          </label>
        </div>
        <button
          type="submit"
          className="w-full py-2 px-4 bg-orange-300 text-black rounded-md shadow-sm hover:bg-orange-500"
        >
          Update
        </button>
        <button
          onClick={onClose}
          className="w-full bg-gray-300 py-2 px-4 rounded hover:bg-gray-500 mt-2"
        >
          Close
        </button>
      </form>
    </div>
  );
}

export default Modal;
