import React, { useState, useEffect } from "react";
import { PencilAltIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import UserUpdate from "./UserUpdate";
import apiService from "../../services/apiService";
import UserCredentials from "./UserCredentials";
import Pagination from "../pagination/Pagination";

const UserList = ({ onUpdateUser }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(10);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUserCredentialsModalOpen, setIsUserCredentialsModalOpen] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await apiService.get(`/api/users?page=${currentPage}&limit=${limit}`);

        if (response.data && response.data.data) {
          setUsers(response.data.data.Items);
          setTotalPages(response.data.data.TotalPages);
          setTotalRecords(response.data.data.TotalCount);
        } else {
          setError("Unexpected response format");
        }
      } catch (error) {
        setError("Failed to fetch users");
        console.error("Failed to fetch users", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [currentPage, limit]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setCurrentPage(1); 
  };

  const handleUserOpenModal = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleUserCloseModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleAddUserOpenModal = () => {
    setIsUserCredentialsModalOpen(true);
  };

  const handleAddUserCloseModal = () => {
    setIsUserCredentialsModalOpen(false);
  };

  const onUpdateUserHandler = (updatedUser) => {
    onUpdateUser(updatedUser);
    // Refresh the user list here if needed
    const fetchUsers = async () => {
      try {
        const response = await apiService.get(`/api/users?page=${currentPage}&limit=${limit}`);
        if (response.data && response.data.data) {
          setUsers(response.data.data.Items);
        } else {
          setError("Unexpected response format");
        }
      } catch (error) {
        setError("Failed to fetch users");
        console.error("Failed to fetch users", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div className="flex justify-between">
        <div className="mb-4 flex-3/4">
          <h2 className="text-2xl font-bold mb-4" style={{ color: "#225282" }}>User List</h2>
        </div>
        <div className="mb-4 flex-1/4">
          <button
            className="bg-orange-400 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded"
            onClick={handleAddUserOpenModal}
          >
            Add User
          </button>
        </div>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">First Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Active</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Admin</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Deleted</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Update User</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {users.map((user) => (
            <tr key={user.ID}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{user.ID}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{user.username}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{user.firstName}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{user.lastName}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{user.email}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                {user.isActive ? (
                  <CheckCircleIcon className="h-5 w-5 inline text-green-500" />
                ) : (
                  <XCircleIcon className="h-5 w-5 inline text-red-500" />
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                {user.isAdmin ? (
                  <CheckCircleIcon className="h-5 w-5 inline text-green-500" />
                ) : (
                  <XCircleIcon className="h-5 w-5 inline text-red-500" />
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                {user.isDelete ? (
                  <CheckCircleIcon className="h-5 w-5 inline text-green-500" />
                ) : (
                  <XCircleIcon className="h-5 w-5 inline text-red-500" />
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                <button
                  onClick={() => handleUserOpenModal(user)}
                  className="text-blue-500 hover:text-blue-700"
                >
                  <PencilAltIcon className="h-5 w-5 inline" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Component */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        totalRecords={totalRecords}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
        limit={limit}
      />

      {isModalOpen && selectedUser && (
        <UserUpdate onClose={handleUserCloseModal} user={selectedUser} onUpdateUser={onUpdateUserHandler} />
      )}

      {isUserCredentialsModalOpen && (
        <UserCredentials onClose={handleAddUserCloseModal} />
      )}
    </div>
  );
};

export default UserList;
