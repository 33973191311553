import React from 'react';
import XeniLogo from '../../assest/img/XENI-LOGO_V7_A.png';

const Loader = () => {
  return (
    <div className="fixed inset-0 flex flex-col justify-center items-center bg-gray-900 bg-opacity-75 z-50">
      <img
        src={XeniLogo}
        alt="Loading..."
        className="animate-spin-slow w-32 h-28 sm:w-32 sm:h-24 mb-4"
      />
      <div className="w-32 h-2 sm:w-24 bg-gray-300 rounded-full overflow-hidden"> {/* Adjusted width for progress bar */}
        <div className="h-full bg-orange-500 animate-progress"></div>
      </div>
      <br/>
      <h1 className='text-white'>Loading...</h1>
      <style jsx>{`
        // @keyframes spin {
        //   0% {
        //     transform: rotateY(0deg);
        //   }
        //   100% {
        //     transform: rotateY(360deg);
        //   }
        // }
        @keyframes progress {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(100%);
          }
        }
        // .animate-spin-slow {
        //   animation: spin 3s linear infinite;
        // }
        .animate-progress {
          animation: progress 1.5s ease-in-out infinite;
        }
      `}</style>
    </div>
  );
};

export default Loader;

