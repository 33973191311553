import React, { useState, useEffect, useContext, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "tailwindcss/tailwind.css";
import {
  PencilAltIcon,
  TrashIcon,
  SearchIcon,
  FilterIcon,
} from "@heroicons/react/solid";
import ConfirmationModal from "./ConfirmationModal";
import AddCertificateModal from "../certificates/AddCertificateModal";
import Modal from "../../Modal1";
import apiService from "../../services/apiService";
import agencyService from "../../services/agencyService";
import { AgencyStatusContext } from "../../contexts/agencyStatusContext";
import Loader from "../loader/Loader";
import { jwtDecode } from "jwt-decode";
import Pagination from "../pagination/Pagination";
import { ExclamationIcon } from "@heroicons/react/solid";

const AgencyList = ({ onUpdateAgency }) => {
  const [agencies, setAgencies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isAddCertificateModalOpen, setIsAddCertificateModalOpen] =
    useState(false);
  const [isAgencyCredentialsModalOpen, setIsAgencyCredentialsModalOpen] =
    useState(false);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isCAAModalOpen, setIsCAAModalOpen] = useState(false);
  const [caaRecords, setCAARecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [statusOptions] = useState([
    { id: 1, name: "new" },
    { id: 2, name: "In-Progress" },
    { id: 6, name: "Activated" },
    { id: 9, name: "Deleted" },
    { id: 7, name: "SSL Records Removed by Agency" },
    { id: 8, name: "Store Record Removed by Agency" },
  ]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filteredAgencies, setFilteredAgencies] = useState([]);

  // Add a ref to the filter box
  const filterRef = useRef(null);

  // Function to handle clicks outside the filter box
  const handleClickOutside = (event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setIsFilterOpen(false);
    }
  };
  useEffect(() => {
    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const token = localStorage.getItem("token");
  const { agencyStatusResponse, setAgencyStatusResponse } =
    useContext(AgencyStatusContext);

  useEffect(() => {
    const initialize = async () => {
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const isAdmin = decodedToken?.isAdmin;
          setIsAdmin(isAdmin === true);
        } catch (error) {
          console.error("Failed to decode token", error);
        }
      }
      await fetchAgencies();
    };

    initialize();
  }, [token, page, limit]);

  const fetchAgencies = async () => {
    setLoading(true);
    try {
      const response = await apiService.get(
        `/api/agencies/?page=${page}&limit=${limit}&agencyName=${searchTerm}`
      );
      const { Items, TotalPages, TotalCount } = response.data;
      setAgencies(Items);
      setFilteredAgencies(Items);
      setTotalPages(TotalPages);
      setTotalRecords(TotalCount);
    } catch (error) {
      setError("Failed to fetch agencies");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAgencies();
  }, [searchTerm, page, limit]);

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setPage(newPage);
  };

  const handleOpenModal = (agency) => {
    setSelectedAgency(agency);
    setIsModalOpen(true);
  };

  const handleAddAgency = () => {
    setSelectedAgency(null);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedAgency(null);
  };

  const handleOpenConfirmationModal = (agency) => {
    setSelectedAgency(agency);
    setIsConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setSelectedAgency(null);
  };

  const handleDeleteAgency = async () => {
    setIsLoaderVisible(true);
    try {
      const response = await apiService.delete(
        `/api/agencies/${selectedAgency.ID}`
      );
      if (response.status === 200) {
        toast.success("Agency deleted successfully!");
        fetchAgencies(page);
      }
    } catch (error) {
      console.error("Failed to delete agency", error);
    } finally {
      setIsLoaderVisible(false);
      handleCloseConfirmationModal();
    }
  };

  const handleCheckCAA = async (agency) => {
    setSelectedAgency(agency);
    try {
      const response = await apiService.get(
        `/api/certificates/check-caa?domain=${agency.agencyDomainName}`
      );
      if (response.data.isCAA_Enabled) {
        if (response.data.isAwsCAAExist) {
          toast.success(
            "AWS CAA record found. Proceeding with certificate request.",
            { icon: "🙂" }
          );
          handleOpenAddCertificateModal(agency);
          setIsCAAModalOpen(false);
        } else {
          setCAARecords(response.data.records);
          setIsCAAModalOpen(true);
        }
      } else {
        toast.success(
          "CAA record is not present. Proceeding with certificate request.",
          { icon: "🙂" }
        );
        handleOpenAddCertificateModal(agency);
      }
    } catch (error) {
      toast.error("Failed to check CAA records. Please try again.");
    }
  };

  const handleOpenAddCertificateModal = (agency) => {
    if (agency) {
      if (agency.fkAgencyStatus > 2) {
        apiService
          .post(`/api/certificates/AddDomainInCloudfront`, {
            agencyID: agency.ID,
            storeID: agency.fkAssignedStore,
          })
          .then((response) => {
            toast.success("Store Added Successfully");
            fetchAgencies(page, limit);
          })
          .catch((error) => {
            toast.error(
              "Certificate Creation in progress... please try again after some time."
            );
          });
      } else {
        setSelectedAgency(agency);
        setIsAddCertificateModalOpen(true);
      }
    } else {
      console.error("No agency selected");
    }
  };

  const renderCertButton = (status) => {
    switch (status) {
      case 1:
        return "Request Certificate";
      case 2:
        return "View Certificate Request";
      case 3:
      case 4:
      case 5:
        return "Queued";
      case 6:
        return "Activated";
      case 7:
        return "Revoked";
      case 8:
        return "Expired";
      case 9:
        return "Removed";
      default:
        return "Request Certificate";
    }
  };

  const handleCloseAddCertificateModal = () => {
    setIsAddCertificateModalOpen(false);
    setSelectedAgency(null);
  };

  const handleAddAgencyOpenModal = () => {
    setIsAgencyCredentialsModalOpen(true);
  };

  const handleAddAgencyCloseModal = () => {
    setIsAgencyCredentialsModalOpen(false);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(parseInt(newLimit));
    setPage(1);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setPage(1);
  };

  const handleStatusSelect = (status) => {
    setSelectedStatus(status);
    setIsFilterOpen(false);

    // Filter agencies based on selected status
    let filtered;
    if (status.id === 1) {
      // New
      filtered = agencies.filter((agency) => agency.fkAgencyStatus === 1);
    } else if (status.id === 2) {
      // In-Progress
      filtered = agencies.filter(
        (agency) => agency.fkAgencyStatus >= 2 && agency.fkAgencyStatus <= 5
      );
    } else if (status.id === 6) {
      // Activated
      filtered = agencies.filter((agency) => agency.fkAgencyStatus === 6);
    } else {
      // Other statuses
      filtered = agencies.filter(
        (agency) => agency.fkAgencyStatus === status.id
      );
    }

    setFilteredAgencies(filtered);
  };

  const handleRequestCertificate = async (agency) => {
    try {
      const response = await apiService.post(
        `/api/certificates/AddCertificate`,
        {
          agencyID: agency.ID,
          storeID: agency.fkAssignedStore,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Certificate request submitted successfully!");
      fetchAgencies(page, limit);
    } catch (error) {
      toast.error("Failed to request certificate. Please try again.");
    }
  };

  const handleCheckCertificateStatus = async (agency) => {
    const token = localStorage.getItem("token");
    const decodedToken = jwtDecode(token);
    const certificateArn = decodedToken?.certificateArn;

    if (!certificateArn) {
      toast.error("Certificate ARN not found in token.");
      return;
    }

    try {
      const response = await apiService.post(
        `/api/certificates/ValidateCertificateStatus`,
        {
          certificateArn: certificateArn,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Certificate status validated successfully!");
      // Handle response as needed
    } catch (error) {
      toast.error("Failed to validate certificate status. Please try again.");
    }
  };

  const handleUpdateAgency = async (updatedAgency) => {
    await fetchAgencies(); 
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="overflow-x-auto">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <div className="flex-1">
          <h2 className="text-2xl font-bold" style={{ color: "#225282" }}>
            Agency List
          </h2>
        </div>
        <div className="flex-1 mt-4 md:mt-0">
          <div className="relative mb-4 w-64 transition-all duration-300 ease-in-out hover:w-full group">
            <input
              type="text"
              placeholder="Search agencies..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setPage(1);
              }}
              className="w-full p-2 border-2 border-white rounded-lg transition-all duration-300 ease-in-out 
                focus:border-orange-600 focus:ring focus:ring-orange-200 focus:ring-opacity-50
                group-hover:border-orange-600 bg-gray-100"
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              <SearchIcon className="h-5 w-5 text-gray-400 group-hover:text-orange-600" />
            </div>
          </div>
        </div>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Agency Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Domain Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Assigned Store
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Agency Status
              <FilterIcon
                className="h-5 w-5 text-gray-500 cursor-pointer inline-block ml-2"
                onClick={() => setIsFilterOpen(!isFilterOpen)}
              />
              {isFilterOpen && (
                <div
                  ref={filterRef}
                  className="absolute bg-gradient-to-r from-blue-100 to-blue-300 border border-gray-300 rounded-lg shadow-lg p-4 mt-1 transition-opacity duration-200 ease-in-out backdrop-filter backdrop-blur-lg"
                  style={{ width: "200px" }}
                >
                  {statusOptions.map((status) => (
                    <div
                      key={status.id}
                      className="px-4 py-2 cursor-pointer hover:bg-[#225282] hover:text-white"
                      onClick={() => handleStatusSelect(status)}
                    >
                      {status.name}
                    </div>
                  ))}
                </div>
              )}
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Active
            </th>
            {isAdmin && (
              <>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Update
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Delete
                </th>
              </>
            )}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Request Certificate
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredAgencies && filteredAgencies.length > 0 ? (
            filteredAgencies.map((agency) => (
              <tr key={agency.ID}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {agency.agencyName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {agency.agencyDomainName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {agency.store.storeName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {agency.agencyStatus.statusName}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {agency.isActive ? "Yes" : "No"}
                </td>
                {isAdmin && (
                  <>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <PencilAltIcon
                        className="h-5 w-5 text-blue-500 cursor-pointer"
                        onClick={() => handleOpenModal(agency)}
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <TrashIcon
                        className="h-5 w-5 text-red-500 cursor-pointer"
                        onClick={() => handleOpenConfirmationModal(agency)}
                      />
                    </td>
                  </>
                )}
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <button
                    disabled={agency.fkAgencyStatus > 2}
                    onClick={() => {
                      if (agency.fkAgencyStatus === 1) {
                      //  handleRequestCertificate(agency);
                      handleCheckCAA(agency);
                      } else if (agency.fkAgencyStatus === 3) {
                        handleCheckCertificateStatus(agency);
                      } else {
                        handleOpenAddCertificateModal(agency);
                      }
                    }}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                  >
                    {renderCertButton(agency.fkAgencyStatus)}
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="px-6 py-4 text-center text-gray-500">
                No agencies found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <Pagination
        currentPage={page}
        totalPages={totalPages}
        totalRecords={totalRecords}
        limit={limit}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          agency={selectedAgency}
          onUpdateAgency={handleUpdateAgency}
        />
      )}
      {isAddCertificateModalOpen && (
        <AddCertificateModal
          isOpen={isAddCertificateModalOpen}
          onClose={handleCloseAddCertificateModal}
          agency={selectedAgency}
        />
      )}
      {isConfirmationModalOpen && (
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          onClose={handleCloseConfirmationModal}
          onConfirm={handleDeleteAgency}
        />
      )}
      {isAgencyCredentialsModalOpen && (
        <Modal
          isOpen={isAgencyCredentialsModalOpen}
          onClose={handleAddAgencyCloseModal}
          agency={null}
          onAddAgency={handleAddAgency}
        />
      )}

      {isCAAModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full">
            <div className="p-6">
              <div className="flex items-center justify-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900 text-center mb-4">
                  CAA Records Found
                </h3>
              </div>
              <p className="text-sm text-gray-500 mb-4 text-center">
                AWS CAA record not found. You need to add one of these CAA
                records to your domain:
              </p>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {["Host", "Type", "Flag", "Tag", "Value", "TTL"].map(
                        (header) => (
                          <th
                            key={header}
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {header}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {caaRecords && caaRecords.length > 0 ? ( // Check if caaRecords is defined and has items
                      caaRecords.map((record, index) => (
                        <tr
                          key={index}
                          className={
                            index % 2 === 0 ? "bg-gray-50" : "bg-white"
                          }
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {record.host}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {record.record_type}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {record.flag}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {record.tag}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {record.value}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {record.ttl}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="6"
                          className="px-6 py-4 text-center text-gray-500"
                        >
                          No CAA records found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="mt-6">
                <button
                  onClick={() => {
                    setIsCAAModalOpen(false);
                    handleCheckCAA(selectedAgency);
                  }}
                  className="w-full px-4 py-2 bg-orange-300 text-black text-base font-medium rounded-md shadow-sm hover:bg-orange-600"
                >
                  Check CAA
                </button>
                <button
                  onClick={() => setIsCAAModalOpen(false)}
                  className="w-full px-4 py-2 bg-gray-300 text-black text-base font-medium rounded-md shadow-sm hover:bg-gray-500 mt-1"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default AgencyList;
